import styled from "styled-components";

export const Container = styled.div`
  max-width: 100vw;
  min-width: 100vw;
  width: 100%;

  height: 140px;

  background: #fff;
`;

export const Content = styled.div`
  width: 1000px;
  margin: 0 auto;
  padding: 35px 0;

  display: flex;

  background: #fff;

  p {
    margin-top: 16px;
  }

  .column-two {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    a {
      margin-left: 12px;
      border-bottom: 2px solid transparent;

      transition: border 0.2s;
    }

    a:hover {
      border-bottom: 2px solid #009ada;
    }
  }

  @media (max-width: 500px) {
    width: 100vw;
    width: 100%;

    padding: 35px 32px;

    flex-direction: column;
    align-items: center;
    text-align: center;

    .column-one {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-bottom: 16px;
    }
  }
`;
