import styled from "styled-components";

export const Container = styled.div`
  max-width: 100vw;
  min-width: 100vw;
  width: 100%;

  .logo {
    max-width: 100vw;
    min-width: 100vw;
    width: 100%;
    height: 60px;

    background: #fff;
    opacity: 0.9;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
  }
`;

export const FirstBlock = styled.div`
  max-width: 100vw;
  min-width: 100vw;
  width: 100%;

  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  height: 100%;

  margin-top: 48px;

  > img {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }

  @media (max-width: 500px) {
    max-width: 100vw;
    width: 100%;

    display: flex;
    align-items: center;
    margin: 0 auto;

    padding: 0 32px;

    > img {
      left: 0;
    }

    .text-present {
      max-width: 100vw;
      width: 100%;
    }

    .text-present h1 {
      z-index: 2;
    }

    .WomanAndSquare {
      display: none;
    }
  }
`;

export const Content = styled.div`
  max-width: 1000px;
  width: 100%;

  display: flex;
  justify-content: space-between;

  margin: 0 auto;

  .text-present {
    padding-top: 80px;

    h1 {
      color: #009ada;
      font-weight: 800;
      font-size: 48px;
    }
  }

  .image-present {
    padding-top: 64px;
    display: flex;
    justify-content: flex-end;
    height: 450px;
  }

  @media (max-width: 500px) {
    .image-present {
      display: none;
      visibility: hidden;
    }

    h1 {
      max-width: 100vw;
      width: 100%;
      text-align: center;
      margin-bottom: 100px;
    }
  }
`;

export const SecondBlock = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  position: relative;

  min-width: 100vw;
  max-width: 100vw;
  width: 100%;

  background: #dee7ff;

  img {
    position: absolute;
    bottom: 0;
    z-index: 1;
  }

  .second-block-content {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding-top: 80px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;

    h1 {
      width: 248px;
      height: 110px;

      font-weight: 800;
      font-size: 48px;
      line-height: 55px;

      color: #009ada;
    }

    p {
      width: 445px;
      height: 135px;

      font-weight: 500;
      font-size: 18px;
      line-height: 30px;

      margin-top: 24px;
      z-index: 2;
    }
  }

  @media (max-width: 500px) {
    max-width: 100vw;
    width: 100%;

    display: flex;
    flex-direction: initial;
    align-items: initial;
    text-align: initial;

    .second-block-content {
      display: flex;
      align-items: center;
      align-items: center;
      text-align: center;
    }

    h1 {
      max-width: 100vw;
      width: 100%;

      font-size: 40px;
      line-height: 50px;

      margin-bottom: 64px;
    }

    p {
      max-width: 100vw;
      width: 100%;

      padding: 0 32px;
    }
  }
`;

export const ThirdBlock = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  position: relative;

  min-width: 100vw;
  max-width: 100vw;
  width: 100%;

  background: #f7f7f7;

  .third-block-content {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding-top: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      height: 110px;

      font-weight: 800;
      font-size: 48px;
      line-height: 55px;

      text-align: center;

      color: #009ada;
    }

    p {
      width: 815px;
      height: 75px;

      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      text-align: center;

      color: #292929;
    }

    .cards {
      display: flex;
      justify-content: space-between;
      align-items: center;

      max-width: 900px;
      width: 100%;

      padding-top: 64px;

      .card {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 220px;
        height: 300px;

        background: #dee7ff;

        border-radius: 16px;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.2),
          0 15px 12px rgba(0, 0, 0, 0.02);

        p {
          width: 100%;
          margin-top: 16px;
        }

        img {
          border-radius: 0 0 16px 16px;
          object-fit: cover;
          width: 100%;
          height: 280px;

          transition: 0.1s;
        }
      }

      .card:hover img {
        transform: scale(0.9);
      }
    }
  }

  @media (max-width: 500px) {
    max-height: 100%;
    height: 100%;

    h1 {
      margin-bottom: 64px;
    }

    p {
      max-width: 100vw;
      width: 100%;
      padding: 0 32px;
    }

    .cards {
      display: flex;
      flex-direction: column;
    }

    .card {
      margin-top: 32px;
    }
  }
`;

export const FourthBlock = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  position: relative;

  min-width: 100vw;
  max-width: 100vw;
  width: 100%;

  background: linear-gradient(
    0deg,
    rgba(222, 231, 255, 1) 0%,
    rgba(247, 247, 247, 1) 100%
  );
  /* box-shadow: 0px -15px 38px rgba(0, 0, 0, 0.1); */

  > img {
    position: absolute;
    z-index: 1;
    bottom: 0;
    /* left: 120px; */
    left: calc((100vw - 1000px) / 2);
  }

  .fourth-block-content {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding-top: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-weight: 800;
      font-size: 48px;
      line-height: 55px;

      text-align: center;

      color: #009ada;
    }

    .boxes {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      padding-top: 120px;

      width: 100%;

      .box {
        width: 450px;
        height: 150px;
        border-radius: 16px;

        background: #fff;

        display: flex;
        align-items: center;
        z-index: 2;

        img {
          margin: 0 64px;
          width: 32px;
        }

        .infos {
          span {
            font-size: 24px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    .fourth-block-content .boxes {
      flex-direction: column;
      align-items: center;
      padding: 32px;
    }

    .fourth-block-content .boxes .box {
      margin-bottom: 32px;
      max-width: 100vw;
      width: 100%;
      margin: 0 32px 32px 32px;
    }

    .fourth-block-content .boces .box img {
      margin: 0 16px;
    }

    > img {
      position: absolute;
      z-index: 1;
      bottom: 0;
      /* left: 120px; */
      left: calc(((100vw) / 2) - 35vw);
    }
  }
`;
