import React from "react";

import {
  Container,
  Content,
  FirstBlock,
  SecondBlock,
  ThirdBlock,
  FourthBlock,
} from "./styles";

import Footer from "../../components/Footer";

import logo from "../../assets/logo.svg";
import WomanAndSquare from "../../assets/woman-and-square.svg";
import SquareCut from "../../assets/woman-sitted.svg";
import backgroundSecondBlock from "../../assets/background-second-block.svg";
import designerMan from "../../assets/designer-man.svg";
import cameraMan from "../../assets/camera-man.svg";
import programmerMan from "../../assets/programmer-man.svg";
import webcorpBuild from "../../assets/webcorp-build.svg";

import phone from "../../icons/phone.svg";
import mail from "../../icons/mail.svg";

const Home: React.FC = () => {
  return (
    <Container>
      <section className="logo">
        <img src={logo} alt="Logo" />
      </section>

      <FirstBlock>
        <Content>
          <div className="text-present">
            <h1>Mais do que uma empresa de tecnologia</h1>
          </div>

          <div className="image-present">
            <img
              className="WomanAndSquare"
              src={WomanAndSquare}
              alt="Mulher pintando página"
            />
          </div>
        </Content>
        <img src={SquareCut} alt="Sitted woman" />
      </FirstBlock>

      <SecondBlock>
        <div className="second-block-content">
          <h1>Onde tudo começou</h1>
          <p>
            Somos uma união que permitiu um motor capaz de acelerar todo tipo de
            projeto web integrado, para qualquer corporação. Um departamento que
            virou uma empresa, capaz de traduzir sua missão em seu próprio nome,
            WebCorp.
          </p>
        </div>
        <img src={backgroundSecondBlock} alt="Tree with birds and sellphone" />
      </SecondBlock>

      <ThirdBlock>
        <div className="third-block-content">
          <h1>Núcleo de Negócios</h1>
          <p>
            Somos especialistas e apaixonados por comunicação e inovação,
            desenvolvendo e utilizando ferramentas incriveis, focadas em
            entregar a melhor solução possivel.
          </p>

          <div className="cards">
            <div className="card">
              <p>Design</p>
              <img src={designerMan} alt="Designer" />
            </div>

            <div className="card">
              <p>Audiovisual</p>
              <img src={cameraMan} alt="Designer" />
            </div>

            <div className="card">
              <p>Desenvolvimento</p>
              <img src={programmerMan} alt="Programmer" />
            </div>
          </div>
        </div>
      </ThirdBlock>

      <FourthBlock>
        <div className="fourth-block-content">
          <h1>Contato</h1>

          <div className="boxes">
            <div className="box">
              <img src={phone} alt="Ícone de telefone" />

              <div className="infos">
                <span>Telefone</span>
                <p>(41) 3180-0264</p>
              </div>
            </div>

            <div className="box">
              <img src={mail} alt="Ícone de e-mail" />

              <div className="infos">
                <span>Email</span>
                <p>oi@webcorp.com.br</p>
              </div>
            </div>
          </div>
        </div>

        <img src={webcorpBuild} alt="WebCorp building" srcSet="" />
      </FourthBlock>

      <Footer />
    </Container>
  );
};

export default Home;
