import React from "react";

import logo from "../../assets/logo.svg";
import linkedin from "../../assets/linkedin.svg";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";

import { Container, Content } from "./styles";

const Footer: React.FC = () => {
  return (
    <Container>
      <Content>
        <div className="column-one">
          <img src={logo} alt="WebCorp" />
          <p>© Copyright 2020 WebCorp - Todos os direitos reservados.</p>
        </div>

        <div className="column-two">
          <a
            href="https://www.linkedin.com/company/webcorpbr"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="Logo do Linkedin" />
          </a>
          <a
            href="https://www.facebook.com/WebCorp-Br-102877268264440"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="Logo do Linkedin" />
          </a>
          <a
            href="https://www.instagram.com/webcorpbr/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="Logo do Linkedin" />
          </a>
        </div>
      </Content>
    </Container>
  );
};

export default Footer;
